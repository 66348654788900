@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin:0px;
    padding:0px;
    list-style:none;
    text-decoration:none !important;
    font-family: "Nunito Sans", serif;
}

li{
    padding:30px 0px;
    cursor: pointer;
    width: 200px;
    margin:5px 0px;
    border-radius:10px;
    border:.5px solid #d3d4d7;
}

li:hover{
    background-color:white;
}

.description{
    font-size:15px;
    margin-top:10px;
}

i{
    font-size:26px;
}

.block{
    margin-bottom:30px;
}

.choice{
    display:grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap:20px !important;
}

.bloc{
    display:flex;
    justify-content:space-between;
    align-items:center !important;
}

.bloc{
    width:100% !important;
}

.bloc-1{
    width:40% !important;
    padding-left:50px;
    padding-right:60px;
}

.bloc-1 h2{
    font-weight:bold;
    font-size:34px;
}

.bloc-1 p{
    font-size:23px;
    margin-top:40px;
}

.job{
    width: 60% !important;
    margin-top:70px;
}

.bloc-info{
    margin-right:20px;
    padding-right:40px;
    width:50% !important;
}

.bloc-info h3{
    font-size:33px;
    font-weight:bold;
    margin-bottom:10px;
}

.bloc-info p{
    margin:20px 0px;
    font-size:18px;
}

.bloc-2{
    background-color:#f8f8f8;
    width:60% !important;
}

.suivant button{
    padding:10px 30px;
    color:white !important;
    background-color:#01158E !important;
    font-size:18px;
    border:0px solid;
    margin-bottom:30px;
    border-radius:30px;
}

.name{
    font-size:20px;
}

.index{
    margin-top:20px;
}

.correct{
    color:white !important;
    border:.5px solid #d3d4d7;
    background-color:white !important;
}

.wrong{
    color:black !important;
    border:.5px solid #d3d4d7;
    background-color:white !important;
}

.choice .selected {
    color:black !important;
    border:.5px solid #d3d4d7;
    background-color:white !important;
}


.option-image{
    width:40px !important;
}

.info{
    border:2px solid red !important;
}

/* Style Form */
.form-container{
    width:50% !important;
}

.field-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border:2px solid red;
}

.field-group div {
    width: 48%;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #DF750D;
}

input {
    width: 100%;
    padding:14px;
    border: 1px solid #ccc;
    border-radius: 15px;
    font-size: 16px;
}

input:focus {
    outline: none;
}

.error {
    color: #d9534f;
    font-size: 14px;
    margin-top: 5px;
}

.form-container button {
    width: 100%;
    padding: 14px 0px;
    background-color:#01158E !important;
    color: #fff;
    border: none;
    border-radius:30px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-container button:hover {
    background-color: #c56309;
}

.champs{
    margin:20px 0px;
}

.formulaire{
    margin-top:20px;
}

.img-5{
    width:50%;
}

.imag{
    display:flex;
    justify-content:center;
    margin-top:20px;
}

.imag img{
    width:300px !important;
}

.next{
    width:30% !important;
    padding:14px 7px !important;
}

.title{
    padding:14px 0px !important;
    font-size:30px;
    font-weight:700;
}

.header-form{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:25px;
    background-color:#FAE20C;
    padding:14px 0px;
    border-radius:30px;
}

.check{
    width:5% !important;
}

.img-5{
    border-radius:50%;
    margin-right:15px;
}

.imag{
    height:80vh;
    display:flex;
    justify-content:center;
    align-items:center;
}

@media (max-width:1024px) {
    .img-5{
        display:none !important;
    }

    .form-container{
        width:100% !important;
    }

    .bloc-1 {
        display:none;
    }

    .bloc-2{
        width:100% !important;
    }

    .bloc-info{
        display:none !important;
    }

    .title{
        padding:14px 10px !important;
        font-size:25px;
        font-weight:700;
    }

}

@media (max-width:720px) {
    .choice{
        display:flex;
        flex-direction:column;
        padding:0px !important;
    }

    .choice-tablette{
        display:flex;
        justify-content:center !important;
        padding-top:-20px 0px !important;
    }

    li{
        padding: 20px 5px;
        cursor: pointer;
        width: 150px;
    }

    .suivant button{
        color:white !important;
        font-size:18px;
        border:0px solid;
        margin:0px;
        width:70% !important;
    }

    .img-5{
        display:none !important;
    }

    .form-container{
        width:100% !important;
    }

    .bloc-1{
        display:none !important;
    }

    .bloc-2{
        width:100% !important;
    }

    .choice{
        display:grid !important;
        grid-template-columns: repeat(2, 1fr);
        gap:20px !important;
    }

    .bloc-info{
        display:none !important;
    }

    .title{
        padding:14px 0px !important;
        font-size:22px;
        font-weight:700;
    }

    .next{
        padding:10px 7px !important;
        margin:10px 0px !important;
    }
}